
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91rid_93spi0KXyc4dTwh2M2_v49xtA9PcEddYmlJrXObJl2OGoMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId]/[rid].vue?macro=true";
import { default as indexu_V186g1I_6TQ8gsYM00AKW1NNRfmW3Ozzthx6GOr2gMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/[connectorId]/claim-lab/index.vue?macro=true";
import { default as _91connectorId_93GTdSlJj_45mR8fp8JiykxUD473kgwb0S_GnjY5M0_ZvhQMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId].vue?macro=true";
import { default as api_45clientsJe_e__45G0Pzkp68_WKyMej5ll1ADfkAwo5M15UVtZCAAMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/account/api-clients.vue?macro=true";
import { default as indexTPg2avXfQRjT4HstOGRB2dmK_45p_45bjBh9Ofc_45LovioLsMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/account/index.vue?macro=true";
import { default as usersoLAuUDiL8RF0Erav1md0ffHhyeLns_45wkhn1d91j4vlwMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/account/users.vue?macro=true";
import { default as api_45adminr7XsuYfG1b8_45_Bwn3xqO7mmsrVWiy1u8Ym5i3dyo7QsMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/api-admin.vue?macro=true";
import { default as app_45configlQ_45iCxZMO42zxVMe4poR6fFM49dLUaSFPXROlppLkpkMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/app-config.vue?macro=true";
import { default as create_45workspaceTCcfy2UwTA4mtE3YReq32wz9torpb2KhkeOC7YcMWoYMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/index/create-workspace.vue?macro=true";
import { default as indexOLeJdZmXgjgH6NCVA8dpuwX1iy_viOBolVt3BsKYSgIMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/index.vue?macro=true";
import { default as _91id_93EUxpRb1daD4_45FdXEs0o7gggrrfDxk1NlvzAlj8yyaFAMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users/[id].vue?macro=true";
import { default as createmehxVAAeYS5k_456OCadqA7Og1FwzVMF3sQtCm17BouTQMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users/create.vue?macro=true";
import { default as indexn0vodTO1VdaD5y1yyg_45ltiVV_LkvcZWtSjVM4D5r2jwMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users/index.vue?macro=true";
import { default as users6OQXo7FCGnJAFnjABxrhjCjf8E9K6jAW5dbKfhQkbs0Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users.vue?macro=true";
import { default as _91connId_93BmHELJ_xnd0wMl7EVVC_Pq1w_45CHVCX1qKd_Q7iIAnK8Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/[connId].vue?macro=true";
import { default as indexXdhT5zOmw2USEwbDd8Ct_DEDhqX0WZrxkqHDW2BFdjcMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/index.vue?macro=true";
import { default as edit05d5lJ2qvp09YJ6uICXDUVD0Od3keFhRCvdtbAiYufgMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index/edit.vue?macro=true";
import { default as indexNE1O472jr3_45zmBoFNiz_WL06sCRTTR9vWUQ3OUsN0jQMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index.vue?macro=true";
import { default as _91id_93yoYj3UEA2gsBFgf0C6hsP9JDl7o3tLav2N0mhatBx5kMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id].vue?macro=true";
import { default as indexp0mA3V3YHpkQfpPpsq_45huEdmxDNdzWVnt33C25REB4AMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/index.vue?macro=true";
import { default as app_45statusdRGVvmgymVmhiYiGI91eLa6Pc_45k9KrbSYI5fNudWCzoMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/app-status.vue?macro=true";
import { default as changelogqeM0N_w1AK91hhGWnCWYdiKO2bFvsCK4Ff4X1lV2GtAMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/changelog.vue?macro=true";
import { default as influencing_45claimsDn3i9nNLNMZqIaMzl4W0J1jvK2IEFj1j5MngySSM8zIMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/claims/[id]/[insightId]/influencing-claims.vue?macro=true";
import { default as _91id_93VfPzGqyJxsFKZ2_45aDDcCdWvxbsWuwyqjTU1SIgw9HkQMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/claims/[id].vue?macro=true";
import { default as claimsgpJs_45rjtA0wOC_45KLPdPASzb_45wW0PfMipz37hN_454ZBJEMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/claims.vue?macro=true";
import { default as dashboardawEmqXoUlRVetSgH_45dhVqkKF12Eq3DTrVw89XfUNczgMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/dashboard.vue?macro=true";
import { default as edit3NEWb24_Du3MsSMc7dVtISyxgGL_tFD8ePFWM8iamPkMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/[id]/edit.vue?macro=true";
import { default as index5vg4qKwfJ_45a3d1n_4510dRw7hxzuI9y9bXb7h7RA23PKEMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/[id]/index.vue?macro=true";
import { default as create2lOPaArUpqs_u4EewAiDwTgp1ne8P5BPszrSC6DnR9cMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/create.vue?macro=true";
import { default as index9prLK_3FZ72Fr7eAoqvLHYnalzIq4hVhqqeX5qGXSnUMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/index.vue?macro=true";
import { default as exclusionsoldnoriJkjp4FXFWgI_45SPpWXolGaFtGm_pe2bp4UlYAMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions.vue?macro=true";
import { default as index3Pb2fdo6rThON46xs4sbAk0NuIzutnMXRqnreYfcdFsMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/index.vue?macro=true";
import { default as maintenancebEapbml_45GsretMsL0MK4gNDrdOeYInPNgcr2UfgOmmAMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/maintenance.vue?macro=true";
import { default as providers_45impacted47FbHeWMNPl2DL_dWQ_6_45exGqe_458ReFt5oJAxQzwOScMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies/[id]/providers-impacted.vue?macro=true";
import { default as _91id_93zvyhOk2QV8MOnB_cnYf4LjmOvPqvySfWTndPjK1gHZUMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies/[id].vue?macro=true";
import { default as indexZQtgmHIZrQlEIxlITiWacuWCb4G3fqKgOcu9tiKBqYIMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies/index.vue?macro=true";
import { default as policiesrJHjMJKdpsK3N_Bb_45NmFYb4s7en5lfEdadwcLEwY2w4Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies.vue?macro=true";
import { default as reset_45password_45confirmStJBYdQI20mFOFTQrnB_45Vjze1OvsEH6erpDzIM2IC9wMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/reset-password-confirm.vue?macro=true";
import { default as reset_45passwordbSg_tLpgeTIgSDsaanGnbeXC95Kg8zQ_KM2rJEDBpO4Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/reset-password.vue?macro=true";
import { default as signinaVhixDrBaF96VXkFbx9S87KdkzjB8E4TNo7ej_45bIhWkMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signin.vue?macro=true";
import { default as confirmSE8kuxQYG4SbQR0Xz_Gx8vg7_IQrFTtIGNfCLyWl4WoMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signup/confirm.vue?macro=true";
import { default as indexomMkJvkRmACCIU9V67JObOEtF5sSdZeyAfR5FnsF7HsMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signup/index.vue?macro=true";
import { default as signup4BiR8SQWiUNNg5by4aFTpZvz82uTFtE_45m9zoNdlw9koMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signup.vue?macro=true";
import { default as welcomeDAjImvrPe1Qyk8zgX5ICnhb0hfkQKJNmYOtRtw4e7wwMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/welcome.vue?macro=true";
import { default as auth_45callbacko4QM_45nsQ3QQx1N_aMOnHSXVHeDKKP4VZSvLBuiyU4t8Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue?macro=true";
import { default as auth_45loginLJfsGKPjrHDRsYv4RKd_WwJAqrGt_451IkPfHduxo1sHIMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue?macro=true";
import { default as auth_45statustACt2y4z0ElBKTAQVsnQtsaUlBA9R_jkO0Eb3MjCnLAMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue?macro=true";
import { default as samlIrWI8hMBKg3jQtg_45lqYR3tc8M_t6L4_45GKhwXzsbIa9sMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth/src/pages/saml.vue?macro=true";
export default [
  {
    name: "connectorId",
    path: "/:connectorId()",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId].vue"),
    children: [
  {
    name: "connectorId-claim-lab-claimId-rid",
    path: "claim-lab/:claimId()/:rid()",
    meta: _91rid_93spi0KXyc4dTwh2M2_v49xtA9PcEddYmlJrXObJl2OGoMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId]/[rid].vue")
  },
  {
    name: "connectorId-claim-lab",
    path: "claim-lab",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/[connectorId]/claim-lab/index.vue")
  }
]
  },
  {
    name: "account-api-clients",
    path: "/account/api-clients",
    meta: api_45clientsJe_e__45G0Pzkp68_WKyMej5ll1ADfkAwo5M15UVtZCAAMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/account/api-clients.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexTPg2avXfQRjT4HstOGRB2dmK_45p_45bjBh9Ofc_45LovioLsMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/account/index.vue")
  },
  {
    name: "account-users",
    path: "/account/users",
    meta: usersoLAuUDiL8RF0Erav1md0ffHhyeLns_45wkhn1d91j4vlwMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/account/users.vue")
  },
  {
    name: "admin-api-admin",
    path: "/admin/api-admin",
    meta: api_45adminr7XsuYfG1b8_45_Bwn3xqO7mmsrVWiy1u8Ym5i3dyo7QsMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/api-admin.vue")
  },
  {
    name: "admin-app-config",
    path: "/admin/app-config",
    meta: app_45configlQ_45iCxZMO42zxVMe4poR6fFM49dLUaSFPXROlppLkpkMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/app-config.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexOLeJdZmXgjgH6NCVA8dpuwX1iy_viOBolVt3BsKYSgIMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/index.vue"),
    children: [
  {
    name: "admin-index-create-workspace",
    path: "create-workspace",
    meta: create_45workspaceTCcfy2UwTA4mtE3YReq32wz9torpb2KhkeOC7YcMWoYMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/index/create-workspace.vue")
  }
]
  },
  {
    name: users6OQXo7FCGnJAFnjABxrhjCjf8E9K6jAW5dbKfhQkbs0Meta?.name,
    path: "/admin/users",
    meta: users6OQXo7FCGnJAFnjABxrhjCjf8E9K6jAW5dbKfhQkbs0Meta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users.vue"),
    children: [
  {
    name: "admin-users-id",
    path: ":id()",
    meta: _91id_93EUxpRb1daD4_45FdXEs0o7gggrrfDxk1NlvzAlj8yyaFAMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users-create",
    path: "create",
    meta: createmehxVAAeYS5k_456OCadqA7Og1FwzVMF3sQtCm17BouTQMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users/create.vue")
  },
  {
    name: "admin-users",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users/index.vue")
  }
]
  },
  {
    name: _91id_93yoYj3UEA2gsBFgf0C6hsP9JDl7o3tLav2N0mhatBx5kMeta?.name,
    path: "/admin/workspace/:id()",
    meta: _91id_93yoYj3UEA2gsBFgf0C6hsP9JDl7o3tLav2N0mhatBx5kMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id].vue"),
    children: [
  {
    name: "admin-workspace-id",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index.vue"),
    children: [
  {
    name: "admin-workspace-id-index-connector-connId",
    path: "connector/:connId()",
    meta: _91connId_93BmHELJ_xnd0wMl7EVVC_Pq1w_45CHVCX1qKd_Q7iIAnK8Meta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/[connId].vue")
  },
  {
    name: "admin-workspace-id-index-connector",
    path: "connector",
    meta: indexXdhT5zOmw2USEwbDd8Ct_DEDhqX0WZrxkqHDW2BFdjcMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/index.vue")
  },
  {
    name: "admin-workspace-id-index-edit",
    path: "edit",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index/edit.vue")
  }
]
  }
]
  },
  {
    name: "admin-workspace",
    path: "/admin/workspace",
    meta: indexp0mA3V3YHpkQfpPpsq_45huEdmxDNdzWVnt33C25REB4AMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/index.vue")
  },
  {
    name: "app-status",
    path: "/app-status",
    meta: app_45statusdRGVvmgymVmhiYiGI91eLa6Pc_45k9KrbSYI5fNudWCzoMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/app-status.vue")
  },
  {
    name: "changelog",
    path: "/changelog",
    meta: changelogqeM0N_w1AK91hhGWnCWYdiKO2bFvsCK4Ff4X1lV2GtAMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/changelog.vue")
  },
  {
    name: "claims",
    path: "/claims",
    meta: claimsgpJs_45rjtA0wOC_45KLPdPASzb_45wW0PfMipz37hN_454ZBJEMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/claims.vue"),
    children: [
  {
    name: "claims-id",
    path: ":id()",
    meta: _91id_93VfPzGqyJxsFKZ2_45aDDcCdWvxbsWuwyqjTU1SIgw9HkQMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/claims/[id].vue"),
    children: [
  {
    name: "claims-id-insightId-influencing-claims",
    path: ":insightId()/influencing-claims",
    meta: influencing_45claimsDn3i9nNLNMZqIaMzl4W0J1jvK2IEFj1j5MngySSM8zIMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/claims/[id]/[insightId]/influencing-claims.vue")
  }
]
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardawEmqXoUlRVetSgH_45dhVqkKF12Eq3DTrVw89XfUNczgMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/dashboard.vue")
  },
  {
    name: exclusionsoldnoriJkjp4FXFWgI_45SPpWXolGaFtGm_pe2bp4UlYAMeta?.name,
    path: "/exclusions",
    meta: exclusionsoldnoriJkjp4FXFWgI_45SPpWXolGaFtGm_pe2bp4UlYAMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions.vue"),
    children: [
  {
    name: "exclusions-id-edit",
    path: ":id()/edit",
    meta: edit3NEWb24_Du3MsSMc7dVtISyxgGL_tFD8ePFWM8iamPkMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/[id]/edit.vue")
  },
  {
    name: "exclusions-id",
    path: ":id()",
    meta: index5vg4qKwfJ_45a3d1n_4510dRw7hxzuI9y9bXb7h7RA23PKEMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/[id]/index.vue")
  },
  {
    name: "exclusions-create",
    path: "create",
    meta: create2lOPaArUpqs_u4EewAiDwTgp1ne8P5BPszrSC6DnR9cMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/create.vue")
  },
  {
    name: "exclusions",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: index3Pb2fdo6rThON46xs4sbAk0NuIzutnMXRqnreYfcdFsMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenancebEapbml_45GsretMsL0MK4gNDrdOeYInPNgcr2UfgOmmAMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/maintenance.vue")
  },
  {
    name: policiesrJHjMJKdpsK3N_Bb_45NmFYb4s7en5lfEdadwcLEwY2w4Meta?.name,
    path: "/policies",
    meta: policiesrJHjMJKdpsK3N_Bb_45NmFYb4s7en5lfEdadwcLEwY2w4Meta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies.vue"),
    children: [
  {
    name: "policies-id",
    path: ":id()",
    meta: _91id_93zvyhOk2QV8MOnB_cnYf4LjmOvPqvySfWTndPjK1gHZUMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies/[id].vue"),
    children: [
  {
    name: "policies-id-providers-impacted",
    path: "providers-impacted",
    meta: providers_45impacted47FbHeWMNPl2DL_dWQ_6_45exGqe_458ReFt5oJAxQzwOScMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies/[id]/providers-impacted.vue")
  }
]
  },
  {
    name: "policies",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies/index.vue")
  }
]
  },
  {
    name: "reset-password-confirm",
    path: "/reset-password-confirm",
    meta: reset_45password_45confirmStJBYdQI20mFOFTQrnB_45Vjze1OvsEH6erpDzIM2IC9wMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/reset-password-confirm.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordbSg_tLpgeTIgSDsaanGnbeXC95Kg8zQ_KM2rJEDBpO4Meta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/reset-password.vue")
  },
  {
    name: "signin",
    path: "/signin",
    meta: signinaVhixDrBaF96VXkFbx9S87KdkzjB8E4TNo7ej_45bIhWkMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signin.vue")
  },
  {
    name: signup4BiR8SQWiUNNg5by4aFTpZvz82uTFtE_45m9zoNdlw9koMeta?.name,
    path: "/signup",
    meta: signup4BiR8SQWiUNNg5by4aFTpZvz82uTFtE_45m9zoNdlw9koMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signup.vue"),
    children: [
  {
    name: "signup-confirm",
    path: "confirm",
    meta: confirmSE8kuxQYG4SbQR0Xz_Gx8vg7_IQrFTtIGNfCLyWl4WoMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signup/confirm.vue")
  },
  {
    name: "signup",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signup/index.vue")
  }
]
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomeDAjImvrPe1Qyk8zgX5ICnhb0hfkQKJNmYOtRtw4e7wwMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/welcome.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: auth_45callbacko4QM_45nsQ3QQx1N_aMOnHSXVHeDKKP4VZSvLBuiyU4t8Meta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: auth_45loginLJfsGKPjrHDRsYv4RKd_WwJAqrGt_451IkPfHduxo1sHIMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue")
  },
  {
    name: "auth-status",
    path: "/auth/status",
    meta: auth_45statustACt2y4z0ElBKTAQVsnQtsaUlBA9R_jkO0Eb3MjCnLAMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue")
  },
  {
    name: "AuthSaml",
    path: "/saml",
    meta: samlIrWI8hMBKg3jQtg_45lqYR3tc8M_t6L4_45GKhwXzsbIa9sMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth/src/pages/saml.vue")
  }
]