import validate from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.2_db0@0.3.1_en_2f1e0e36a877054c71e36434821f4fa8/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45required_45global from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/middleware/auth-required.global.ts";
import _02_45route_45rules_45global from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/middleware/02.route-rules.global.ts";
import _03_45rbac_45global from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/middleware/03.rbac.global.ts";
import _04_45route_45history_45global from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/middleware/04.route-history.global.ts";
import feature_45flags_45global from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/middleware/feature-flags.global.ts";
import manifest_45route_45rule from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.2_db0@0.3.1_en_2f1e0e36a877054c71e36434821f4fa8/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45required_45global,
  _02_45route_45rules_45global,
  _03_45rbac_45global,
  _04_45route_45history_45global,
  feature_45flags_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "claim-lab": () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/middleware/claim-lab.ts"),
  "all-connectors": () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/middleware/all-connectors.ts"),
  "connector-required": () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/middleware/connector-required.ts"),
  "rialtic-admin": () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/middleware/rialtic-admin.ts"),
  "workspace-system-admin": () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/middleware/workspace-system-admin.ts")
}