export enum ProcedureCodeType {
  CPT = 'CPT',
  HCPCS = 'HCPCS',
}

export enum FilterType {
  INPUT = 'input',
  DATE_RANGE = 'date_range',
  MULTIPLE = 'multiple',
  SINGLE = 'single',
}

export enum FilterNames {
  DOS_RANGE = 'date_of_service_range',
  LINE_DOS_START = 'line_date_of_service_start',
  LINE_DOS_END = 'line_date_of_service_end',
  SORT_BY = 'sort_by',
  LOB = 'lob',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
}

export enum AppConfigKeys {
  featureAccountApiClients = 'feature_account_api_clients',
  featureChatRip = 'feature_chat_rip',
  featureClaimIDRedirect = 'feature_claim_id_redirect',
  featureClientSSO = 'feature_client_sso',
  featureHiroTimestamps = 'feature_hiro_timestamps',
  featurePeriodicRefresh = 'feature_periodic_refresh',
  featurePolicyMetadata = 'feature_policy_metadata',
  featureZendeskLink = 'feature_zendesk_link',
  maintenance = 'maintenance',
  featureConfigCopy = 'feature_config_copy',
  featureClaimsExport = 'feature_claims_export',
}

export enum ClaimLineStatus {
  EXCLUDED = 'excluded',
  NOT_PAYABLE = 'not_payable',
  NO_INSIGHTS = 'no_insights',
  NEW_LINE = 'new_line',
  OBSERVATION = 'observation',
  RESOLVED = 'resolved',
}

export enum ClaimType {
  Professional = 'Professional',
  ASCProfessional = 'ASC billed on professional form',
  ASCInstitutional = 'ASC billed on institutional form',
  Outpatient = 'Institutional outpatient',
  Inpatient = 'Institutional inpatient',
  NoClaimTypes = 'No claim types',
}
export const ClaimTypeValues = Object.values(ClaimType)

export enum HistoryType {
  Off = 'Off',
  Days = 'Lookback days',
  Infinite = 'Infinite',
}
export const HistoryTypeValues = Object.values(HistoryType)
